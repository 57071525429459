import React from 'react';
// import { Switch, Route } from "react-router-dom";

// components
import Page from "./Page";
// import SectionAdmin from "./SectionAdmin";
// import NotFound from "./NotFound";
import SectionMain from "./SectionMain";
import SectionPricing from "./SectionPricing";
import SectionTry from "./SectionTry";
import SectionFAQ from "./SectionFAQ";
import SectionTestimonials from "./SectionTestimonials";

function Home() {
    return (
        <Page>
            {/* <Switch> */}
            {/* <Route path="/" exact> */}
            <SectionMain />
            {/* <SectionAdmin /> */}
            <SectionPricing />
            <SectionTry />
            <SectionFAQ />
            <SectionTestimonials />
            {/* </Route> */}
            {/* <Route path="/"><NotFound /></Route> */}
            {/* </Switch> */}
        </Page>
    );
}

export default Home;
