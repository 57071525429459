import React, { useContext } from 'react';
// import YoutubeVideo from './YoutubeVideo';
// import ReactPlayer from 'react-player'
// import YouTube from 'react-youtube';
// import { Link } from "react-router-dom";
// import Unity, { UnityContext } from "react-unity-webgl";

// import Unity from './Unity';
import ImageFadeIn from 'react-image-fade-in';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// const vimeoId = '527475700';
const youtubeId = 'sTX2edKecQM';
// const vimeoId = '253989945';

function Section() {

    // const unityObj = {
    //     loaderUrl: process.env.PUBLIC_URL + "/3d/Build/UnityLoader.js",
    //     dataUrl: process.env.PUBLIC_URL + "/3d/Build/Build.data.unityweb",
    //     frameworkUrl: process.env.PUBLIC_URL + "/3d/Build/Build.wasm.framework.unityweb",
    //     codeUrl: process.env.PUBLIC_URL + "/3d/Build/Build.wasm.code.unityweb",
    // };


    // const unityContext = new UnityContext(unityObj);

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    return (
        <div className="section section--main"
        // style={{ backgroundImage: `url(/assets/images/section/hero/${appState.randomBgImage}.jpg)` }}
        >
            <ImageFadeIn className="cover" src={`/assets/images/section/hero/${appState.randomBgImage}.jpg`} />
            {/* <ImageFadeIn className="cover" loadAsBackgroundImage={true} src={`/assets/images/section/hero/${appState.randomBgImage}.jpg`} /> */}
            {/* <iframe className="video" src={`https://player.vimeo.com/video/${vimeoId}?muted=1&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;title=0&amp;byline=0&amp;portrait=0&amp;playsinline=0&amp;autopause=0&amp;controls=0`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" title="Sample Video" data-ready="true"></iframe> */}
            <iframe className="video" src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&loop=1&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0&playlist=${youtubeId}`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" title="Sample Video" allowFullScreen></iframe>

            <div className="container">

                <div>
                    <h2>Make your<br />3d web <span>gallery</span>.</h2>
                    <button onClick={(e) => { appDispatch({ type: 'setPopupRegister', data: true }) }} className="btn btn--large btn--callToAction" >Try for free</button>
                    {/* <a className="btn btn--large" href="@demo">Demo</a> */}
                    <button className="btn btn--large" onClick={(e) => { appDispatch({ type: 'setPopupDemo', data: true }) }}>Demo</button>
                    {/* <Link className="btn btn--large" to={"@demo"}>Demo</Link> */}
                </div>
                <div>
                    <div className="mini-video">
                        <div className="loading-bar"></div>
                        <iframe src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&loop=1&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0&playlist=${youtubeId}`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" title="Sample Video" allowFullScreen></iframe>
                        {/* <iframe src={`https://player.vimeo.com/video/${vimeoId}?muted=1&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;title=0&amp;byline=0&amp;portrait=0&amp;playsinline=0&amp;autopause=0&amp;controls=0`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" title="Sample Video" data-ready="true"></iframe> */}
                    </div>
                </div>

            </div>

            {/* <Unity unityContext={unityContext} /> */}
            {/* <div id="unityContainer"></div> */}
            {/* <Unity /> */}

        </div>
    );
}

export default Section;